import { Input, InputProps } from 'baby-design'
import { useEffect, useState } from 'react'

type inputValue = string | number | readonly string[] | undefined

export default function CurrencyInput({
  inputProps = {},
  ...props
}: InputProps) {
  const { value, onChange, onBlur, ...inputPropsRest } = inputProps
  const [currencyValue, setCurrencyValue] = useState<inputValue>(
    formatValue(value)
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueFromInput = e.target.value
    setCurrencyValue(valueFromInput)

    const newValue =
      valueFromInput !== '' ? `${unFormatValue(valueFromInput)}` : ''
    const event = { ...e, target: { ...e.target, newValue } }

    onChange?.(event)
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (isValueNumeric(currencyValue)) {
      setCurrencyValue(formatValue(currencyValue))
    }

    onBlur?.(e)
  }

  useEffect(() => {
    if (
      value !== currencyValue &&
      value !== unFormatValue(currencyValue) && // Don't update unless the value has a material change.
      isValueNumeric(value)
    ) {
      setCurrencyValue(value)
    }
  }, [value])

  return (
    <Input
      inputProps={{
        value: currencyValue,
        onBlur: handleBlur,
        onChange: handleChange,
        ...inputPropsRest,
      }}
      {...props}
    />
  )
}

const formatValue = (value: inputValue): string => {
  if (!value || value === 'null') {
    return ''
  }

  const valueAsFloat = unFormatValue(value).toFixed(2)
  return `$${valueAsFloat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}

function unFormatValue(value: inputValue | null): number {
  if (value === undefined || value === null) return NaN

  return parseFloat(value.toString().replace(/(\$|,)/g, ''))
}

const isValueNumeric = (value: inputValue): boolean =>
  !Number.isNaN(unFormatValue(value))
