import { Radio, RadioGroup } from 'components/forms'
import {
  ConsolidatedShippingIcon,
  ConsolidatedShippingLeafIcon,
} from 'components/icons'
import { FieldProp } from 'redux-form'
import css from './consolidated-shipping-form-fields.scss'

interface FormFieldProps {
  consolidationChoice: FieldProp<string>
}

export default ({ consolidationChoice }: FormFieldProps) => {
  const optInLabel = (
    <div>
      <span className="text-bold">
        Yes, I'd like to keep it simple! Ship my gifts in fewer boxes*{' '}
        <ConsolidatedShippingLeafIcon className={css.leafIcon} />
      </span>
      <br />
      <span className="xsmall text-muted">
        I understand that by selecting this, my gifts may take up to 2 weeks to
        ship to me.
      </span>
    </div>
  )

  const optOutLabel =
    'No, send me Babylist Shop gifts as soon as they are purchased'

  const introCopy = `Try Simple Shipping by Babylist! We'll bundle your gifts purchased
      from the Babylist Shop into fewer shipments.* Better for your entryway
      and the environment.`

  const { error, ...consolidationChoiceInputProps } = consolidationChoice

  return (
    <div>
      <div className={css.consolidationText}>
        <div className={css.consolidatedShippingIconContainer}>
          <ConsolidatedShippingIcon />
        </div>
        <div>
          {introCopy}
          {error && <div className="h7 text-danger mtm">Must select one:</div>}
        </div>
      </div>
      <div className="flex center">
        <RadioGroup className="mtxl mbl flex col">
          <Radio
            {...consolidationChoiceInputProps}
            label={optInLabel}
            checked={consolidationChoiceInputProps.value === 'rolling'}
            onChange={() => consolidationChoiceInputProps.onChange('rolling')}
          />
          <Radio
            {...consolidationChoiceInputProps}
            label={optOutLabel}
            checked={consolidationChoiceInputProps.value === 'none'}
            onChange={() => consolidationChoiceInputProps.onChange('none')}
          />
        </RadioGroup>
      </div>
    </div>
  )
}
