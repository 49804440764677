import { EditItemDetailsFavoriteSetting } from '../components/Favorite'

import Image from 'components/image'
import { IconButton, Textarea } from 'baby-design'
import { EditPencil } from 'baby-design/icons'

import css from './fields.style.scss'
import { RegItem } from 'src/types/regItem'
import { EditItemDetailsCashFundPreferences } from '../components/CashFundPreferences'
import ProductInfoDrawer from '../../ProductInfoDrawer/ProductInfoDrawer'
import NiceModal from '@ebay/nice-modal-react'
import CashFundPreferencesDrawer from '../../CashFundPreferencesDrawer/CashFundPreferencesDrawer'
import EditRegItemDrawer from '../EditRegItemDrawer'
import { CategoryDropdown } from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/CategoryDropdown'
import { getCategoriesAsKeyValue } from 'src/routes/(registry)/list/[slug]/utils/productHelpers'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { useQuery } from '@tanstack/react-query'
import { Registry } from 'src/types/registry'
import { FETCH_REGISTRY_KEY, fetchRegistry } from 'src/api/queries'
import {
  CrowdfundFieldsProps,
  CrowdfundFormProps,
} from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/fields/RegItemFields.types'
import useMarkPurchasedEnabled from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/hooks/useMarkPurchasedEnabled'
import CrowdfundContributionsSection from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/fields/CrowdfundFields/components/CrowdfundContributionsSection/CrowdfundContributionsSection'

export function CrowdfundForm({
  purchasable = false,
  regItemFields,
  registry,
  updateRegItemFields,
  handleDelete,
  reservations,
  onEditProductInfo,
  onEditCashFundPreferences,
  onRegItemUpdated,
}: CrowdfundFormProps) {
  const isMarkUnmarkEnabled = useMarkPurchasedEnabled()
  const { categories } = registry || {}
  return (
    <div className={css.fields}>
      <div className={css.fields__item_intro}>
        <Image
          src={regItemFields.imgUrl}
          alt={regItemFields.title}
          className={css.fields__item_intro_img}
        />
        <div className={css.fields__item_intro_info}>
          <div className={css.fields__item_title}>{regItemFields.title}</div>
        </div>
        <IconButton
          variant="fill-inverted"
          size="sm"
          className={css.fields__image_edit}
          onClick={onEditProductInfo}
        >
          <EditPencil />
        </IconButton>
      </div>
      <div className={css.fields__item_details}>
        <CategoryDropdown
          regItemFields={regItemFields}
          categories={getCategoriesAsKeyValue(categories || [])}
          updateRegItemFields={updateRegItemFields}
        />
        <div className={css.fields__item_input}>
          <Textarea
            label="Notes to friends and family "
            size="md"
            value={regItemFields.description || undefined}
            onChange={(e) =>
              updateRegItemFields({ description: e.target.value })
            }
            placeholder="Add a note..."
          />
        </div>
        {isMarkUnmarkEnabled && purchasable && (
          <CrowdfundContributionsSection
            regItem={regItemFields}
            reservations={reservations}
            onRegItemUpdated={(updates) => onRegItemUpdated?.(updates)}
          />
        )}
        <EditItemDetailsFavoriteSetting
          regItemFields={regItemFields}
          updateRegItemFields={updateRegItemFields}
        />
        <EditItemDetailsCashFundPreferences
          registry={registry}
          openCashFundPreferencesDrawer={onEditCashFundPreferences}
        />
      </div>
      {handleDelete && (
        <>
          <div className={css.fields__divider} />
          <div className={css.fields__item_delete}>
            <a className={css.fields__delete_link} onClick={handleDelete}>
              Delete item
            </a>
          </div>
        </>
      )}
    </div>
  )
}

export const CrowdfundFields = (props: CrowdfundFieldsProps) => {
  const [currentUser] = useCurrentUser()

  const registryId = currentUser?.currentRegistry?.id

  const { data: registry, refetch } = useQuery<Registry>({
    enabled: !!registryId,
    queryKey: [FETCH_REGISTRY_KEY, registryId],
    queryFn: () => fetchRegistry(registryId),
  })

  const { regItem } = props
  const openProductInfoDrawer = async () => {
    const response: RegItem | undefined = await NiceModal.show(
      ProductInfoDrawer,
      { regItem: regItem }
    )
    if (response) {
      NiceModal.show(EditRegItemDrawer, { regItem: response })
    }
  }

  const openCashFundPreferencesDrawer = async () => {
    const response = await NiceModal.show(CashFundPreferencesDrawer)
    if (response) {
      refetch()
    }
  }

  return (
    <CrowdfundForm
      {...props}
      purchasable
      registry={registry}
      onEditCashFundPreferences={openCashFundPreferencesDrawer}
      onEditProductInfo={openProductInfoDrawer}
    />
  )
}
