import React from 'react'

import classNames from 'classnames'
import css from './Toast.styles.scss'

import { ToastProps } from './Toast.types'
import { Close } from '../../icons'
import ToastButton from './components/ToastButton/ToastButton'
import ToastLink from './components/ToastLink/ToastLink'
import { IconButton } from '../../index'
import ToastTransition from './components/ToastTransition/ToastTransition'
import ToastImage from './components/ToastImage/ToastImage'

const Toast: React.FC<ToastProps> = ({
  className,
  cta: ctaProps,
  dismissible,
  eyebrow,
  imageProps,
  isOpen,
  onDismiss,
  text,
  title,
  variant,
}) => {
  const toastClasses = classNames(css.Toast, className, {
    [css[`Toast--${variant}`]]: variant,
  })

  const toastImage = imageProps.image ? (
    imageProps.image
  ) : (
    <ToastImage
      alt={imageProps.imageAlt as string}
      src={imageProps.imageSrc as string}
    />
  )

  const handleDismiss = () => onDismiss?.()

  return (
    <ToastTransition in={isOpen}>
      <div className={css.Toast__Container}>
        <div className={toastClasses}>
          <div className={css.Toast__Content}>
            <div className={css.Toast__ImageContainer}>{toastImage}</div>
            <div className={css.Toast__TextContent}>
              {eyebrow && <p className={css.Toast__Eyebrow}>{eyebrow}</p>}
              {title && <p className={css.Toast__Title}>{title}</p>}
              <p className={css.Toast__Text}>{text}</p>
            </div>
            <div className={css.Toast__CTAContainer}>
              {variant === 'rich' ? (
                <ToastButton {...ctaProps} />
              ) : (
                <ToastLink {...ctaProps} />
              )}
            </div>
          </div>
          {dismissible && (
            <IconButton
              className={css.Toast__Dismiss}
              data-testid="dismiss-button"
              size="xs"
              variant="standard-inverted"
              onClick={handleDismiss}
            >
              <Close />
            </IconButton>
          )}
        </div>
      </div>
    </ToastTransition>
  )
}

export default Toast
