import { parseErrorMessage } from 'lib/api-utils'
import { FLASH_MESSAGE, UPDATE_CART_SUMMARY } from 'shared/constants'
import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'
import { addCartItem } from 'src/api/queries'
import { RegItem } from 'src/types/regItem'

const addRegItemToCart = (
  regItem: Pick<RegItem, 'id' | 'productId' | 'title'>
) => {
  addCartItem({
    regItemId: regItem.id,
    productId: regItem.productId,
    quantity: 1,
  })
    .then(() => {
      PubSub.publish(FLASH_MESSAGE, {
        message: `${regItem.title} added to cart!`,
        variant: 'success',
      })
      PubSub.publish(UPDATE_CART_SUMMARY, { animateBadge: true })
    })
    .catch((resp) => {
      PubSub.publish(FLASH_MESSAGE, {
        message: parseErrorMessage(resp),
        variant: 'danger',
      })
    })
}
interface UseEzAddToCartProps {
  registryId: number | string
}

const useEZAddToCart = ({ registryId }: UseEzAddToCartProps) => {
  const { flagValue } = useFeatureFlag('24e_ff_ez_atc', false, { registryId })

  return { isEnabledForEZAddToCart: flagValue, addRegItemToCart }
}

export default useEZAddToCart
