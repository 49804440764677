import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

/**
 * @FIXME: dependent on the store implementation of add reg item…
 * We should work on a shared api lib to better facilitate this.
 */
import * as api from 'store/api'
import { InfoButton } from 'components/buttons'
import Image from 'components/image'
import { errorMessage, successMessage } from 'lib/flash-message'
import { singleLineString } from 'lib/single-line-string'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { SUPPORT_EMAIL } from 'shared/constants'
import { track, tracking, useTracking } from 'lib/analytics'
import { getCollaboratorRole } from '../../../utils'
import useCategorizeAnyRegAdd from 'src/hooks/useCategorizeAnyRegAdd'
import { showRegItemAddedToast } from 'shared/RegItemAddedToast/showRegItemAddedToast'

export const QUICK_ADD_STATUS_OPTIONS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
}

// https://github.com/nytimes/react-tracking/issues/138
// overload the track event handler context to set specific quick add event type
const TrackedInfoButton = tracking({ eventType: track.EventType.QUICK_ADD })(
  InfoButton
)

export const QuickAddButton = ({
  status,
  successImgAlt,
  onClick,
  title,
  ...props
}) => {
  if (status === QUICK_ADD_STATUS_OPTIONS.SUCCESS) {
    return (
      <Image
        alt={successImgAlt}
        src="//images.babylist.com/image/upload/v1568670049/icn_check_va95ym.svg"
        lazyLoad={false}
      />
    )
  }

  return (
    <TrackedInfoButton
      {...props}
      circle
      size="small"
      title={title}
      aria-label={title}
      submitting={status === QUICK_ADD_STATUS_OPTIONS.LOADING}
      onClick={onClick}
    >
      <Image
        role="presentation"
        src="//images.babylist.com/image/upload/v1568653819/icn_add_fqc1nn.svg"
        lazyLoad={false}
      />
    </TrackedInfoButton>
  )
}

QuickAddButton.propTypes = {
  status: PropTypes.oneOf(Object.values(QUICK_ADD_STATUS_OPTIONS)),
  successImgAlt: PropTypes.string,
  onClick: PropTypes.func,
}

QuickAddButton.defaultProps = {
  title: 'Quick Add',
  successImgAlt: 'Success',
}

export const addToRegistry = async (
  productId,
  registryId,
  regItemFields = {},
  options
) =>
  api.addToBabylist(
    {
      ...regItemFields,
      product_id: productId,
      registryId,
    },
    options
  )

export const QuickAddRegItem = ({
  eventLocation,
  product,
  source,
  onSuccess,
  onFailure,
  ...props
}) => {
  const [currentUser] = useCurrentUser()
  const [quickAddStatus, setQuickAddStatus] = useState('idle')
  const showItemAddedToast = useCategorizeAnyRegAdd()

  const tracker = useTracking()

  useEffect(() => {
    if (quickAddStatus === 'loading') {
      let canceled = false

      async function addToRegistryAsync() {
        const registryId = currentUser?.registryId

        if (!registryId) {
          setQuickAddStatus('idle')
          return
        }

        try {
          const { regItem } = await addToRegistry(
            product.id,
            currentUser?.registryId,
            {
              source,
            }
          )

          track.addedToRegistry({
            regItem,
            product,
            eventType: track.EventType.QUICK_ADD,
            isFirstRegItem: regItem.isFirstRegItem,
            userId: currentUser?.id,
            email: currentUser?.email,
            collaboratorRole: getCollaboratorRole(currentUser),
          })

          if (canceled) return

          setQuickAddStatus('success')

          const defaultSuccessMessage = `${regItem.title} added to your registry!`

          const flashSuccessMessage = (msg) => {
            if (showItemAddedToast) {
              showRegItemAddedToast({
                currentUser,
                eventLocation,
                regItem: {
                  ...regItem,
                  imgUrl: product.image?.thumbnailUrl,
                },
                tracker,
              })
            } else {
              successMessage(msg)
            }
          }

          onSuccess({
            regItem,
            flashSuccessMessage,
            defaultMessage: defaultSuccessMessage,
          })
        } catch (error) {
          setQuickAddStatus('idle')

          if (canceled) return

          const defaultErrorMessage = singleLineString`
            Oops! There was an issue adding ${product.name} to your registry.
            Please contact <a href="mailto:${SUPPORT_EMAIL}">${SUPPORT_EMAIL}</a>
            if the problem continues.
          `

          onFailure({
            error,
            product,
            flashErrorMessage: (msg) => {
              errorMessage(msg)
            },
            defaultMessage: defaultErrorMessage,
          })
        }
      }

      addToRegistryAsync()

      return () => {
        canceled = true
      }
    }
  }, [quickAddStatus, product.id])

  return (
    <QuickAddButton
      onClick={() => setQuickAddStatus('loading')}
      status={quickAddStatus}
      successImgAlt={`${product.name} added to your registry.`}
      title={`Add ${product.name} to your registry`}
      {...props}
    />
  )
}

QuickAddRegItem.propTypes = {
  eventLocation: PropTypes.string,
  product: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  source: PropTypes.string,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  ...QuickAddButton.propTypes,
}

QuickAddRegItem.defaultFlashSuccessMessage = QuickAddRegItem.defaultProps = {
  onSuccess: ({ flashSuccessMessage, defaultMessage }) =>
    flashSuccessMessage(defaultMessage),
  onFailure: ({ flashErrorMessage, defaultMessage }) =>
    flashErrorMessage(defaultMessage),
}
