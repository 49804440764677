import React from 'react'
import css from './ProductInfoDrawerFields.style.scss'
import { ProductInfoImage } from 'src/routes/(registry)/list/[slug]/components/ProductInfoDrawer/components/Image'
import CurrencyInput from 'src/routes/(registry)/components/form/CurrencyInput'
import { Input } from '../../../../../../../../../baby-design'
import { ProductInfoDrawerFieldsProps } from 'src/routes/(registry)/list/[slug]/components/ProductInfoDrawer/components/ProductInfoDrawerFields/ProductInfoDrawerFields.types'

const ProductInfoDrawerFields: React.FC<ProductInfoDrawerFieldsProps> = ({
  allowImageEdit = false,
  isPriceEditable = false,
  isTitleEditable = false,
  showPrice = false,
  regItemFields,
  onUpdateRegItemFields,
}) => {
  return (
    <div className={css.product_info_drawer__body}>
      <div className={css.product_info_drawer__img}>
        <ProductInfoImage
          regItem={regItemFields}
          updateRegItemFields={onUpdateRegItemFields}
          allowEdit={allowImageEdit}
        />
      </div>
      {showPrice && (
        <CurrencyInput
          className={css.product_info_drawer__price}
          inputProps={{
            disabled: !isPriceEditable,
            value: regItemFields.price,
            onChange: (e) => onUpdateRegItemFields({ price: e.target.value }),
          }}
          label={'Price'}
          size="md"
        />
      )}
      <Input
        className={css.product_info_drawer__title}
        inputProps={{
          disabled: !isTitleEditable,
          value: regItemFields.title,
          onChange: (e) => onUpdateRegItemFields({ title: e.target.value }),
        }}
        label={'Item title'}
        size="md"
      />
    </div>
  )
}

export default ProductInfoDrawerFields
