import css from './AddSecondaryLinkDrawer.style.scss'
import { RegItem, SecondaryLink } from 'src/types/regItem'
import { RightDrawer, NavLink, Input } from 'baby-design'
import CurrencyInput from 'src/routes/(registry)/components/form/CurrencyInput'

import { useEffect, useState } from 'react'
import { saveRegItem } from 'src/api/queries'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { errorMessage, successMessage } from 'lib/flash-message'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import ConfirmUnsavedChangesModal from '../ConfirmUnsavedChangesModal/ConfirmUnsavedChangesModal'
import {
  invalidateRegItemsQuery,
  optimisticallyUpdateRegItem,
} from 'src/routes/(registry)/list/[slug]/utils/registryHelpers'
interface AddSecondaryLinkDrawerProps {
  regItem: RegItem
}

export default NiceModal.create(({ regItem }: AddSecondaryLinkDrawerProps) => {
  const modal = useModal()

  const defaultSecondaryLinkField = {
    id: null,
    url: '',
    price: '',
  }

  const [hasUnsavedChanges, sethasUnsavedChanges] = useState<boolean>(false)
  const [secondaryLinkField, setSecondaryLinkFields] = useState<SecondaryLink>(
    defaultSecondaryLinkField
  )

  useEffect(() => {
    sethasUnsavedChanges(false)
    setSecondaryLinkFields(defaultSecondaryLinkField)
  }, [modal.visible])

  const updateSecondaryLinkFields = (changes: object) => {
    sethasUnsavedChanges(true)
    setSecondaryLinkFields({ ...secondaryLinkField, ...changes })
  }
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: (secondaryLink: SecondaryLink) => {
      const newSecondaryLinks = [
        ...regItem.secondaryLinks,
        {
          url: secondaryLink.url,
          price: secondaryLink.price,
        },
      ]

      optimisticallyUpdateRegItem({
        queryClient,
        updatedRegItem: { ...regItem, secondaryLinks: newSecondaryLinks },
      })

      return saveRegItem(regItem.registry.id, regItem.id, {
        secondaryLinks: newSecondaryLinks,
      })
    },
    onSuccess: ({ regItem: newRegItem }: { regItem: RegItem }) => {
      sethasUnsavedChanges(false)
      invalidateRegItemsQuery({ queryClient, registryId: regItem.registry.id })
      successMessage(`${newRegItem.title} was successfully saved!`)

      modal.resolve(newRegItem)
      modal.hide()
    },
    onError: (error: any) => {
      errorMessage(
        error?.error?.message ||
          'Failed to save. Try refreshing the page. If the problem persists, contact support.'
      )
    },
  })

  const onSave = () => {
    if (!hasUnsavedChanges) {
      return
    }
    // check if url exists in offers and return error if it does
    const existingOffer = regItem.offers.find(
      (offer) =>
        offer.normalUrl?.replace(/^https?:\/\//, '') === secondaryLinkField.url
    )

    if (existingOffer) {
      errorMessage('This link already exists as a primary link.')
      return
    }

    mutation.mutate(secondaryLinkField)
  }

  const handleClose = async () => {
    if (mutation.isLoading) {
      return
    }

    if (hasUnsavedChanges) {
      const confirmUnsavedChanges: boolean | undefined = await NiceModal.show(
        ConfirmUnsavedChangesModal
      )

      if (!confirmUnsavedChanges) {
        return
      }
    }

    modal.resolve(undefined)
    modal.hide()
  }

  return (
    <RightDrawer
      handleClose={handleClose}
      handleDismount={modal.remove}
      isOpen={modal.visible}
      title="Add Buying Option"
      mainIcon="back"
      contextualIcon={
        <NavLink
          variant="eggplant"
          size="lg"
          onClick={onSave}
          disabled={mutation.isLoading || !hasUnsavedChanges}
        >
          Save
        </NavLink>
      }
    >
      <div className={css.secondary_link_drawer__body}>
        <Input
          className={css.secondary_link_drawer__url}
          inputProps={{
            disabled: mutation.isLoading,
            value: secondaryLinkField.url,
            onChange: (e) => updateSecondaryLinkFields({ url: e.target.value }),
          }}
          label={'Product Link'}
          size="md"
        />
        <CurrencyInput
          className={css.secondary_link_drawer__price}
          inputProps={{
            disabled: mutation.isLoading,
            value: secondaryLinkField.price,
            onChange: (e) =>
              updateSecondaryLinkFields({ price: e.target.value }),
          }}
          label={'Price'}
          size="md"
        />
      </div>
    </RightDrawer>
  )
})
