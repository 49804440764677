import React, { useEffect, useMemo } from 'react'
import { Modal } from 'baby-design'
import {
  lockBodyScroll,
  unlockBodyScroll,
} from 'shared/utils/lockBodyScroll/lockBodyScroll'
import { ScrollLockModalProps } from './ScrollLockModal.types'

const ScrollLockModal: React.FC<ScrollLockModalProps> = ({
  handleClose: handleCloseProp,
  onOpen: onOpenProp,
  scrollLockId: scrollLockIdProp,
  ...props
}) => {
  /*
    generateUniqueLockId is necessary right now because we're not on React 18+ yet.
    Once we're on React 18+, we can use the new `useId` hook.
  */
  const generateUniqueLockId = () => {
    const hexValues = '0123456789abcdef'
    const uniqueId = Array(16)
      .fill(0)
      .map(() => hexValues[Math.floor(Math.random() * 16)])
      .join('')
    return `ScrollLockModal-${uniqueId}`
  }

  const scrollLockId = useMemo(
    () => scrollLockIdProp || generateUniqueLockId(),
    []
  )

  const handleClose = () => {
    handleCloseProp()
    unlockBodyScroll(scrollLockId)
  }

  const onOpen = () => {
    lockBodyScroll(scrollLockId)
    onOpenProp?.()
  }

  useEffect(() => () => unlockBodyScroll(scrollLockId), [])

  return <Modal {...props} handleClose={handleClose} onOpen={onOpen} />
}

export default ScrollLockModal
