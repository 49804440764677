import { Dropdown } from 'baby-design'
import { RegItem } from 'src/types/regItem'
import AddUpdateCategoryDrawer from '../../AddUpdateCategoryDrawer/AddUpdateCategoryDrawer'
import NiceModal from '@ebay/nice-modal-react'
import { RegItemCategory } from 'src/types/regItemCategory'

interface SelectValue {
  label: string
  value: any
}

interface CategoryDropdownProps {
  regItemFields: RegItem
  categories: Array<SelectValue>
  updateRegItemFields: (fields: object) => void
}

export function CategoryDropdown({
  regItemFields,
  categories,
  updateRegItemFields,
}: CategoryDropdownProps) {
  const newCategoryValue = {
    value: '_new',
    label: '+ New Category',
  }

  const onChange = async (e: SelectValue | null) => {
    if (e === null) {
      return
    }

    if (e.value === newCategoryValue.value) {
      const response: RegItemCategory | undefined = await NiceModal.show(
        AddUpdateCategoryDrawer
      )
      if (response) {
        updateRegItemFields({ categoryId: response.id })
      }
      return
    }

    updateRegItemFields({ categoryId: e.value })
  }

  return (
    <Dropdown
      label="Category"
      size="md"
      options={[newCategoryValue, ...categories]}
      value={categories.find(
        (category) => category.value === regItemFields.categoryId
      )}
      onChange={onChange}
      placeholder={<>Select&nbsp;Category...</>}
    />
  )
}
