import { IconButton, StoreIcon, LoadingDots, PriceTag } from 'baby-design'
import { Chevron, EditPencil, Plus } from 'baby-design/icons'
import { RegItem } from 'src/types/regItem'
import { Offer } from 'src/types/offer'
import css from './BuyingOptions.style.scss'
import {
  formatDisplayPrice,
  getPriceTagPropsFromPriceDetails,
} from '../../../utils/productHelpers'

const LegacyPriceTag = ({ price }: { price: string | number | undefined }) => (
  <div className={css.BuyingOptions__buying_option_price}>
    {formatDisplayPrice(price)}
  </div>
)

interface BuyingOptionProps {
  offer: Offer
  editOnClick?: () => void
}

const BuyingOption = ({ offer, editOnClick }: BuyingOptionProps) => {
  let priceTagProps = {}
  if (offer.priceDetails) {
    const { msrp, minPrice, maxPrice, currentPrice, onSale, saleCallouts } =
      getPriceTagPropsFromPriceDetails(offer.priceDetails)
    priceTagProps = {
      callouts: saleCallouts,
      currentPrice,
      currentlyInActiveSale: onSale,
      minPrice,
      maxPrice,
      msrp,
    }
  }

  return (
    <div className={css.BuyingOptions__buying_option}>
      <a
        className={css.BuyingOptions__buying_option_icon}
        href={offer.url}
        target="_blank"
      >
        <StoreIcon store={{ name: offer.storeIconName }} />
      </a>
      <div className={css.BuyingOptions__buying_option_name}>
        <a href={offer.url} target="_blank">
          {offer.storeDisplayName || <LoadingDots />}
        </a>
        {editOnClick && (
          <IconButton
            size="xs"
            variant="standard-default"
            onClick={editOnClick}
          >
            <EditPencil />
          </IconButton>
        )}
      </div>
      {offer.priceDetails ? (
        <PriceTag {...priceTagProps} size="sm" variant="compact" />
      ) : (
        <LegacyPriceTag price={offer.price} />
      )}
      <a
        className={css.BuyingOptions__buying_option_chevron_link}
        href={offer.url}
        target="_blank"
      >
        <Chevron className={css.BuyingOptions__buying_option_chevron} />
      </a>
    </div>
  )
}

interface EditItemDetailsBuyingOptionsProps {
  regItemFields: RegItem
  openAddSecondaryLinkDrawer: () => void
  openEditSecondaryLinkDrawer: (offer: Offer) => void
}

export const EditItemDetailsBuyingOptions = ({
  regItemFields,
  openAddSecondaryLinkDrawer,
  openEditSecondaryLinkDrawer,
}: EditItemDetailsBuyingOptionsProps) => {
  const { offers } = regItemFields
  const { secondaryOffers } = regItemFields

  const primaryOffers = offers.filter(
    (offer) =>
      !secondaryOffers.find(
        (secondaryOffer) =>
          secondaryOffer.id === offer.id &&
          secondaryOffer.normalUrl === offer.normalUrl
      )
  )

  return (
    <div className={css.BuyingOptions}>
      <div className={css.BuyingOptions__heading}>Buying Options</div>
      {primaryOffers.map((offer) => (
        <BuyingOption key={offer.id || offer.url} offer={offer as Offer} />
      ))}
      {secondaryOffers.map((offer) => (
        <BuyingOption
          editOnClick={() => openEditSecondaryLinkDrawer(offer)}
          key={offer.id || offer.url}
          offer={offer as Offer}
        />
      ))}
      {regItemFields.secondaryOffers.length < 2 && (
        <div
          className={css.BuyingOptions__add_buying_option}
          onClick={openAddSecondaryLinkDrawer}
        >
          <IconButton size="sm" variant="fill-tertiary">
            <Plus />
          </IconButton>
          <div className={css.BuyingOptions__buying_option_name}>
            Add a buying option
          </div>
          <Chevron className={css.BuyingOptions__buying_option_chevron} />
        </div>
      )}
    </div>
  )
}
