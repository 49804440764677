import { track } from 'lib/analytics'
import { EventLocationType } from 'src/lib/tracking/shared/types.d'
import { registryProperties } from 'src/lib/tracking/shared/bundles/registryProperties'
import { RegItem } from 'src/types/regItem'
import { CurrentUser } from '../../../../../global'

interface TrackRegistryItemDetailsViewedProps {
  currentUser: CurrentUser | undefined
  eventLocation: EventLocationType
  regItem: RegItem
  tracker: any
}

export const trackRegistryItemDetailsViewed = ({
  currentUser,
  eventLocation,
  regItem,
  tracker,
}: TrackRegistryItemDetailsViewedProps) => {
  tracker.trackEvent({
    event: track.registryItemDetailsViewed,
    eventLocation,
    ...registryProperties(currentUser),
    regItemId: regItem.id,
    regItemPrice: regItem.price || 0,
    regItemQuantity: regItem.quantity || 0,
  })
}
