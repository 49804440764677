import React from 'react'
import classNames from 'classnames'
import Close from '../../../../icons/Close.svg'
import IconButton from '../../../IconButton/IconButton'
import { MOBILE_VARIANTS } from '../../constants'
import { ModalTopBarProps } from './ModalTopBar.types'
import css from './ModalTopBar.styles.scss'

const { value: BASIC_VALUE, class: BASIC_CLASS } = MOBILE_VARIANTS.basic

const ModalTopBar: React.FC<ModalTopBarProps> = ({
  handleClose,
  mobileVariant,
  showCloseButton = true,
}) => {
  const topWrapperClass = classNames(css.ModalTopBar, {
    [css[`ModalTopBar__${BASIC_CLASS}`]]: mobileVariant === BASIC_VALUE,
  })

  return (
    <div className={topWrapperClass} data-testid="modal-top-bar">
      {showCloseButton && (
        <IconButton
          aria-label="Close modal"
          className={css.ModalTopBar__close}
          data-testid="closeButton"
          size="sm"
          variant="standard-default"
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      )}
    </div>
  )
}

export default ModalTopBar
