import React, { useEffect } from 'react'
import { CashFundPreferences } from 'src/types/registry'
import { errorMessage } from 'lib/flash-message'
import { LegacyCashFundPreferencesFormProps } from './LegacyCashFundPreferencesForm.types'
import css from './LegacyCashFundPreferencesForm.styles.scss'
import { Checkbox, Input } from '../../../../../../../../../baby-design'
import PaypalMeHelpText from './components/PaypalmeHelpText/PaypalMeHelpText'

const LegacyCashFundPreferencesForm: React.FC<
  LegacyCashFundPreferencesFormProps
> = ({ cashFundPreferences, errors, onPreferencesUpdated }) => {
  const handleUpdateCashFundPreferences = (
    changes: Partial<CashFundPreferences>
  ) =>
    onPreferencesUpdated &&
    onPreferencesUpdated({
      ...cashFundPreferences,
      ...changes,
    })

  const directCheckboxOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleUpdateCashFundPreferences({ direct: e.target.checked })
  }

  const paypalCheckboxOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleUpdateCashFundPreferences({ paypalme: e.target.checked ? '' : null })
  }

  const paypalPrefix = 'paypal.me/'

  const paypalInputValue = paypalPrefix + cashFundPreferences.paypalme

  const paypalInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const paypalme = e.target.value.substring(paypalPrefix.length)
    handleUpdateCashFundPreferences({ paypalme })
  }

  useEffect(() => {
    if (errors) {
      errorMessage(
        errors?.error?.message ||
          'Failed to save. Try refreshing the page. If the problem persists, contact support.'
      )
    }
  }, [errors])

  return (
    <div className={css.cash_fund_preferences}>
      <div className={css.cash_fund_preferences_intro}>
        Choose how you'd like to receive money from gift givers. Each option you
        check will be offered to your gift givers to select from.
      </div>
      <div className={css.cash_fund_preferences_block}>
        <div className={css.cash_fund_preferences_options_heading}>
          Gift Giver Donation Options
        </div>
        <div className={css.cash_fund_preferences_options}>
          <Checkbox
            checked={!!cashFundPreferences.direct}
            id="direct"
            size="lg"
            onChange={directCheckboxOnChange}
          />
          <label htmlFor="direct">
            Receive cash or check by mail or in-person
          </label>
        </div>
        <div className={css.cash_fund_preferences_options}>
          <Checkbox
            size="lg"
            id="paypal"
            checked={cashFundPreferences.paypalme !== null}
            onChange={paypalCheckboxOnChange}
          />
          <label htmlFor="paypal">
            PayPal (Requires you to have a Paypal account)
          </label>
        </div>
      </div>
      {cashFundPreferences.paypalme !== null && (
        <div className={css.cash_fund_preferences_block}>
          <Input
            inputProps={{
              value: paypalInputValue,
              onChange: paypalInputOnChange,
            }}
            label="PayPal.Me Link"
            size="md"
          />
          <PaypalMeHelpText input={cashFundPreferences.paypalme} />
        </div>
      )}
    </div>
  )
}

export default LegacyCashFundPreferencesForm
