import css from './MarkAsPurchasedDrawer.style.scss'
import { RegItem } from 'src/types/regItem'
import {
  RightDrawer,
  NavLink,
  Input,
  Checkbox,
  QuantityPicker,
} from 'baby-design'

import { useEffect, useState } from 'react'
import { createReservation, fetchRegItem } from 'src/api/queries'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { errorMessage, successMessage } from 'lib/flash-message'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import ConfirmUnsavedChangesModal from '../ConfirmUnsavedChangesModal/ConfirmUnsavedChangesModal'
import { optimisticallyUpdateRegItem } from 'src/routes/(registry)/list/[slug]/utils/registryHelpers'

export const MARK_AS_PURCHASED_DRAWER = 'MARK_AS_PURCHASED_DRAWER'

interface MarkAsPurchasedDrawerProps {
  regItem: RegItem
}

export default NiceModal.create(({ regItem }: MarkAsPurchasedDrawerProps) => {
  const modal = useModal()

  const defaultPurchasedFields = {
    name: '',
    quantity: 1,
  }

  const [hasUnsavedChanges, sethasUnsavedChanges] = useState<boolean>(false)
  const [markPurchasedFields, setMarkPurchasedFields] = useState<{
    name: string
    quantity: number
  }>(defaultPurchasedFields)
  const [selfPurchased, setSelfPurchased] = useState<boolean>(false)
  const updateMarkPurchasedFields = (changes: object) => {
    sethasUnsavedChanges(true)
    setMarkPurchasedFields({ ...markPurchasedFields, ...changes })
  }

  useEffect(() => {
    sethasUnsavedChanges(false)
    setMarkPurchasedFields(defaultPurchasedFields)
    setSelfPurchased(false)
  }, [modal.visible])

  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: ({
      reservation,
      params,
    }: {
      reservation: object
      params: object
    }) => createReservation(reservation, params),
    onError: (error: any) => {
      errorMessage(
        error?.error?.message ||
          'Failed to save. Try refreshing the page. If the problem persists, contact support.'
      )
    },
    onSuccess: async () => {
      sethasUnsavedChanges(false)

      const newRegItem = await fetchRegItem(regItem.id, regItem.registryId)
      optimisticallyUpdateRegItem({
        queryClient,
        updatedRegItem: newRegItem,
      })
      successMessage(`${newRegItem.title} was successfully saved!`)
      modal.resolve(newRegItem)
      modal.hide()
    },
  })

  const onSave = () => {
    const reservation = {
      regItemId: regItem.id,
      registryId: regItem.registryId,
      name: selfPurchased ? 'Reserved by you' : markPurchasedFields.name,
      quantity: markPurchasedFields.quantity || 1,
      isPurchased: true,
    }

    mutation.mutate({ reservation, params: { asOwner: true } })
  }

  const shouldShowQuantity =
    regItem.quantityNeeded !== null && regItem.quantityNeeded > 1

  const handleClose = async () => {
    if (mutation.isLoading) {
      return
    }

    if (hasUnsavedChanges) {
      const confirmUnsavedChanges: boolean | undefined = await NiceModal.show(
        ConfirmUnsavedChangesModal
      )

      if (!confirmUnsavedChanges) {
        return
      }
    }
    modal.resolve(undefined)
    modal.hide()
  }

  return (
    <RightDrawer
      handleClose={handleClose}
      handleDismount={modal.remove}
      isOpen={modal.visible}
      title={'Mark As Purchased'}
      contextualIcon={
        <NavLink
          variant="eggplant"
          size="md"
          onClick={onSave}
          disabled={mutation.isLoading || !hasUnsavedChanges}
        >
          Save
        </NavLink>
      }
      mainIcon="back"
    >
      <div className={css.mark_as_purchased_drawer__body}>
        <div className={css.mark_as_purchased_drawer__giver_info}>
          <Input
            inputProps={{
              disabled: selfPurchased || mutation.isLoading,
              value: markPurchasedFields.name,
              onChange: (e) =>
                updateMarkPurchasedFields({ name: e.target.value }),
            }}
            label="Add gift giver"
            size="md"
          />
          <div className={css.mark_as_purchased_drawer__self_info}>
            <Checkbox
              size="md"
              type="secondary"
              checked={selfPurchased}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSelfPurchased(e.target.checked)
                updateMarkPurchasedFields({ name: '' })
              }}
            />
            <div className={css.mark_as_purchased_drawer__self_info_label}>
              I purchased this myself
            </div>
          </div>
        </div>

        {shouldShowQuantity && (
          <div className={css.mark_as_purchased_drawer__quantity}>
            <div className={css.mark_as_purchased_drawer__quantity_title}>
              Gift Quantity
            </div>
            <QuantityPicker
              value={markPurchasedFields.quantity}
              size="md"
              max={
                regItem.quantity === null ? undefined : regItem.quantityNeeded
              }
              onPlusClick={() =>
                updateMarkPurchasedFields({
                  quantity: markPurchasedFields.quantity + 1,
                })
              }
              onMinusClick={() =>
                updateMarkPurchasedFields({
                  quantity: markPurchasedFields.quantity - 1,
                })
              }
              variant="fill"
              type="pdp"
            />
          </div>
        )}
      </div>
    </RightDrawer>
  )
})
