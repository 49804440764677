import React, { ChangeEvent } from 'react'
import { PaymentPreferenceCheckboxProps } from 'src/routes/(registry)/list/[slug]/components/CashFundPreferencesDrawer/components/PaymentPreferenceCheckbox/PaymentPreferenceCheckbox.types'
import Image from 'components/image'
import { Checkbox } from '../../../../../../../../../baby-design'
import css from './PaymentPreferenceCheckbox.styles.scss'

const PaymentPreferenceCheckbox: React.FC<PaymentPreferenceCheckboxProps> = ({
  image,
  key,
  name,
  onCheckChanged,
  selected = false,
}) => {
  const handleSelectionChange = (e: ChangeEvent<HTMLInputElement>) =>
    onCheckChanged && onCheckChanged(e.target.checked)

  const handleContainerClick = () => onCheckChanged && onCheckChanged(!selected)

  return (
    <div
      className={css.PaymentPreferenceCheckbox__Container}
      onClick={handleContainerClick}
    >
      <Checkbox
        checked={selected}
        name={key}
        size="md"
        onChange={handleSelectionChange}
      />
      {image && (
        <Image
          alt={name}
          className={css.PaymentPreferenceCheckbox__Image}
          src={image}
        />
      )}
      {!image && <p className={css.PaymentPreferenceCheckbox__Text}>{name}</p>}
    </div>
  )
}

export default PaymentPreferenceCheckbox
